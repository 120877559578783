   .emotional-support-page {
    text-align: center;
    padding: 10px;
    align-items: center;
    overflow-x: hidden;
  }

  .subHeading {
    font-size: 16px;
    font-style: italic;
  }
  
  
  .page-title {
    font-size: 24px;
    margin-bottom: 20px;
    align-items: center;
  }

  .headerImage {
    width: 350px;
    height: 270px;
  }

.how-it-works-card {
    width: 95%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .how-it-works-heading {
    width: 100%;
    align-items: center;
    font-size: 18px;
    padding-right: -15px;
    padding-left: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5px;
    background-color: #01bbb3;
  }
  
  .how-it-works-steps {
    margin-left: 0;
    text-align: left;
    padding-left: 0px;
    font-style: normal;
  }
  
  .how-it-works-step {
    margin-bottom: 8px;
  }

  .emotional-support-cards {
    display: flex;
    justify-content: space-around;
  }
  
  .emotional-support-card {
    width: 100%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    justify-content: center; 
    align-items: center; 
  }
  
  .name-and-yoe {
    display: flex;
    justify-content: space-between;
  }
  
  .name {
    font-weight: bold;
  }
  
  .yoe {
    background-color: pink;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .qualification,
  .expertise,
  .other-info {
    text-align: left;
    font-style: normal;
  }
  
  .see-more {
    color: blue;
    cursor: pointer;
  }
.qualification{
  font-style: italic; 
}
 .packages-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  justify-content: space-between;
}

.package-button {
  width: calc(50% - 20px);
  margin: 2px;
  border: 2px solid #D1D1D1;
  background-color: #00B2A9;
  text-align: center;
  margin-bottom: 10px;
  margin-right: 13px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; 
}
  .package-price {
    font-weight: bold;
    margin-top: 5px;
    font-size: 18px;
  }

  .package-button label {
    display: flex;
    align-items: center;
    
  }
  .book-now-button {
    margin-left: 2px;
    margin-bottom: 3px;
    background-color: orange;
    padding: 10px 10px;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
    font-style: bold;
  }
  
  .aboutContainer {
    text-align: center;
    margin-top: 20px;
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 10px auto;
  }
  
  .about {
    text-align: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  

  