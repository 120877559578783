
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
}
.title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 0px;
}
.tabText {
  font-size: 16px;
  margin-top: -5px; 
  margin-bottom: 0px;
}

.bannerImageContainer {
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 20px; 
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
}

.bannerImage {
  width: 145%;
  height: auto; 
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  width: 110%;
  justify-content: center; 
  align-items: center; 
  margin-left: 30px;
  margin-right: 20px;
}

.cardLink {
  text-decoration: none;
  color: inherit;
}

.card {
  width: 100%;
  margin: 2px;
  padding: 2px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  margin-right: 20px;
  margin-bottom: 20px;
  justify-content: center; 
  align-items: center; 

}

.card:hover {
  transform: translateY(-5px);
}


.cardImage {
  width: 100%;
  height: 180px;
  border-radius: 8px;
}

.cardDescription {
  font-size: 14px;
  line-height: 1.4;
  margin-top: 15px;
  margin-bottom: 8px;
}

.readMoreLink {
  cursor: pointer;
  color: blue;
  text-decoration: none;
}

.footer {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 3px;
}

.footerImage {
  width: 60px;
  height: auto;
  margin-right: 10px;
  margin-bottom: 1px;
}

.termsAndConditions a {
  text-decoration: none;
  color: blue;
}

.termsAndConditions a:hover {
  text-decoration: none;
}
