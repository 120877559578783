.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header {
  text-align: center;
  margin-top: 20px;
}

.headerImage {
  width: 350px;
  height: 270px;
}

.subHeading {
  font-size: 16px;
  font-style: italic;
}

.headerText {
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
}

.reviews {
  text-align: center;
  margin-top: 5px;
}

.stars {
  font-size: 20px;
  color: orange;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px auto;
}

.about {
  text-align: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.amount {
  font-weight: bold;
  font-size: 18px;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-top: -10px;
}

.separator1 {
  height: 2px;
  width: 100%;
  background-color: green;
  margin: 10px auto;
}

.schedule {
  text-align: center;
  margin-top: 20px;
}

.scheduleHeading {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.packages {
  text-align: center;
  margin-top: 20px;
}

.packageHeading {
  font-size: 16px;
  font-weight: bold;
  color: #45b649;
}

.packageRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.packageCard {
  display: flex;
  height: 120px; 
  flex-direction: column;
  align-items: center;
  border: 2px solid #D1D1D1;
  background-color: #00B2A9;
  padding: 1px;
  text-align: center;
  border-radius: 10px;
  margin: 2px;
  width: calc(60% - 20px);
}
.buyNowButtonContainer {
  text-align: center;
  margin-top: auto;
}

.buyNowButton {
  background-color: orange;
  width: 100%;
  border-radius: 20px;
  padding: -15px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 5px;
}

.buyNowButtonText {
  font-size: 15px;
  font-weight: bold;
  margin: 7px;
}
